<template>
  <form @submit.prevent="submitForm">
    <div class="FormGroup">
      <input
        v-model="formData.name"
        type="text"
        placeholder="Nome"
        required
      >
      <input
        v-model="formData.email"
        type="email"
        placeholder="Email"
        required
      >
      <textarea
        v-model="formData.message"
        placeholder="Mensagem"
        required
      />
    </div>

    <div
      v-if="message.text.length"
      :class="{message: true, [message.type]: true}"
    >
      <p>
        {{ message.text }}
      </p>
    </div>

    <button
      type="submit"
      class="button rounded bg-dark autoSize"
    >
      Enviar
    </button>
    <!--    <a href="/posts/atuacao-geral" class="button rounded bg-dark autoSize"> Saber mais </a>-->
  </form>
</template>

<script>
/* eslint-disable no-alert */
export default {
    name: 'ContactForm',

    data() {
        return {
            formData: {
                name: '',
                email: '',
                message: '',
            },
            message: {
                text: '',
                type: '',
            },
        };
    },
    methods: {
        onVerify(response) {
            this.recaptchaResponse = response;
        },
        async submitForm() {
            try {
                const formDataToSend = new FormData();
                formDataToSend.append('name', this.formData.name);
                formDataToSend.append('email', this.formData.email);
                formDataToSend.append('message', this.formData.message);

                const response = await fetch('https://local-server.coneconsultoria.com.br/email.php', {
                    method: 'POST',
                    body: formDataToSend,
                });

                const result = await response.json();

                if (response.ok) {
                    this.message = {
                        text: 'Mensagem enviada com sucesso!',
                        type: 'success',
                    };
                    // Limpar o formulário se necessário
                    this.formData.name = '';
                    this.formData.email = '';
                    this.formData.message = '';
                } else {
                    alert(result.error);
                }
            } catch (error) {
                this.message = {
                    text: 'Ocorreu um erro ao enviar a mensagem.',
                    type: 'error',
                };
            }
        },
    },
};
</script>

<style scoped>

.message {
    padding: 10px;
    border-radius: 5px;
    margin-top: 1rem;
}

.message p {
    margin: 0;
    font-size: 16px;
}

.message.success {
    background-color: #d4edda;
    color: #155724;
}

.message.error {
    background-color: #f8d7da;
    color: #721c24;
}

.FormGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.FormGroup > * {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}

form input {
    padding: 0.5rem;
    border: 1px solid #ccc;
}

form textarea {
  height: 100px;
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.button {
  padding: 10px 30px;
  border: none;
  font-size: 16px;
}
</style>
