<template>
  <main class="currentPost">
    <slide
      v-if="slides.length"
      :slides="slides"
    />
    <section class="postSection">
      <div class="asideLayout custom">
        <article>
          <div class="contactGroup">
            <aside>
              <div class="address">
                <p class="title">
                  <span>Endereço:</span>
                </p>
                <p>
                  Rua Diogo Moreira, 132 <br class="breakRow"> 12º Andar
                </p>
                <p>
                  05423-010 - Pinheiros<br>
                </p>
                <p>
                  São Paulo - SP<br>
                </p>
                <p>
                  +55 11 3060 8296
                </p>
              </div>

              <!--              <div class="address">-->
              <!--                <p>-->
              <!--                  <span>Endereço: </span>-->
              <!--                </p>-->
              <!--                <p>-->
              <!--                  Rua Diogo Moreira, 132 - -->
              <!--                  <br class="breakRow">12o Andar-->
              <!--                </p>-->
              <!--                <p>-->
              <!--                  <span>CEP:</span> 05423-010-->
              <!--                </p>-->
              <!--                <p>-->
              <!--                  <span>Bairro:</span> Pinheiros-->
              <!--                </p>-->
              <!--                <p>-->
              <!--                  <span>Cidade:</span> São Paulo-->
              <!--                </p>-->
              <!--                <p>-->
              <!--                  <span>Estado:</span> SP-->
              <!--                </p>-->
              <!--              </div>-->
              <!--              <p class="contact">-->
              <!--                <span class="iconSpan">-->
              <!--                  <img-->
              <!--                    :src="phoneIcon"-->
              <!--                    alt="phone icon"-->
              <!--                  >-->
              <!--                </span>Tel + 55 11 3060 8296-->
              <!--              </p>-->
            </aside>
            <div class="content">
              <h1>Contato</h1>
              <ContactForm />
            </div>
          </div>
        </article>
      </div>
    </section>
  </main>
</template>
<script>
import ContactForm from '@/components/ContactForm.vue';

import Slide from '@/components/slide.vue';
import { mapActions } from 'vuex';

export default {
    name: 'ContactPage',
    components: {
        ContactForm,
        Slide,
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.changeMenuVisibility(false);
            vm.changeIsBlog(false);
        });
    },
    data() {
        return {
            // eslint-disable-next-line global-require
            phoneIcon: require('@/assets/phone.svg'),
            slides: [{
                title: 'Fale conosco',
            }],
        };
    },
    methods: {
        ...mapActions(['changeMenuVisibility', 'changeIsBlog']),
    },

};
</script>

<style>
.asideLayout.custom {
  background-color: #1A2F50;
}

.asideLayout.custom .contactGroup aside {
  background-color: #f6a736;
}

.address {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 3px;
  padding: 10px;
}

.address .title {
  margin-bottom: 10px;
}

.address p, .address span {
  font-size: 18px;
  margin: 0;
  /*color: white;*/
  color: #1A2F50;
  font-family: 'Arial', sans-serif;
}

.address span {
  font-weight: bold;
  margin-right: 5px;
}

@media (max-width: 768px) {
  .address {
    padding: 30px 10px;
    margin: 0;
  }
  .address p, .address span {
    color: #1A2F50 !important;
  }

  .address .breakRow {
    display: none;
  }
}

</style>
